body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.operation-teams-drop-preview {
	background-color: rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	margin: 0.25rem;
}

.operation-teams-opacity-ghost {
	transition: all 0.5s ease;
	opacity: 0.8;
	cursor: move;
	transform: rotateZ(5deg);
	box-shadow: 3px 10px 10px 3px rgba(0, 0, 0, 0.3);
}
